<template>
  <div class="background-footer">
    <footer>
      <div class="container-fluid">
        <div class="row gx-5 mb-5" v-if="isMobileDevice && isLogedIn">
          <div class="col-md">
            <img
              class="img-fluid pt-auto"
              alt="Responsive image"
              src="/img/telephant/djumbo-02-05-2.png"
            />
          </div>
          <div class="col-md text-center">
            <button
              class="my-4 bt-wrapper"
              data-target="#logInModal"
              data-toggle="modal"
              id="logInModalLink"
              href="#logInModal"
            >
              Log in
            </button>
            <button
              class="bt-join"
              id="joinModal"
              data-toggle="modal"
              data-target="#joinModal"
            >
              Join
            </button>
          </div>
        </div>
        <div class="row gx-5" v-else>
          <div class="col-md">
            <img
              class="img-fluid pt-auto"
              alt="Responsive image"
              src="/img/telephant/djumbo-02-05-2.png"
            />
          </div>
          <div class="col-md">
            <div class="footer-bold-text-wrapper">Services</div>
            <router-link class="footer-text-wrappe" type="button" to="international"
              >International top-up
            </router-link>
            <router-link class="footer-text-wrappe" type="button" to="calling-rates"
              ><div>International calling rates</div>
            </router-link>
          </div>
          <div class="col-md">
            <div class="footer-bold-text-wrapper">Help</div>
            <router-link class="footer-text-wrappe" type="button" to="support"
              >Contact support
            </router-link>
            <a class="footer-text-wrappe" href="">
              <div>Cookie policy</div>
            </a>
            <a class="footer-text-wrappe" href="">
              <div>Legal Information</div>
            </a>
            <a class="footer-text-wrappe" href="">
              <div>Terms of Service</div>
            </a>
            <a class="footer-text-wrappe" href="">
              <div>Privacy Policy</div>
            </a>
            <router-link class="footer-text-wrappe" type="button" to="invite-bonus"
              >Invite Bonus
            </router-link>
          </div>
          <div class="col-md">
            <div class="footer-bold-text-wrapper">About us</div>
            <router-link class="footer-text-wrappe" type="button" to="t-elephant"
              >T-elephant
            </router-link> <br>
            <router-link class="footer-text-wrappe" type="button" to="how-it-work"
              >How it works
            </router-link>
          </div>
          <div class="col-md">
            <div class="footer-bold-text-wrapper">Product</div>
            <a class="footer-text-wrappe" href="">
              <div>Easy calling</div>
            </a>
            <router-link class="footer-text-wrappe" type="button" to="calling-rates">
              Calling rates
            </router-link>
          </div>
          <div class="col-md">
            <div class="footer-bold-text-wrapper">Language</div>
            <a class="footer-text-wrappe" href="">
              <div>English</div>
            </a>
          </div>
          <div class="col-md">
            <button
              class="my-4 bt-wrapper"
              data-target="#logInModal"
              data-toggle="modal"
              id="logInModalLink"
              href="#logInModal"
            >
              Log in
            </button>
            <button
              class="bt-join"
              id="joinModal"
              data-toggle="modal"
              data-target="#joinModal"
            >
              Join
            </button>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return { isMobileDevice: false };
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // true for mobile device
      this.isMobileDevice = true;
      console.log("mobile device");
    } else {
      // false for not mobile device
      this.mobileDevice = false;
      console.log("not mible device");
    }
  },
  computed: {
    isLogedIn: {
      get() {
        return this.$store.getters.logedSatus;
      },
    },
  },
};
</script>

<style>
.footer-bold-text-wrapper {
  font-weight: 700;
  line-height: 2;
  font-family: "Montserrat", Helvetica;
  color: #000000;
  font-size: 1rem;
  letter-spacing: 0;
  z-index: 10;
  white-space: nowrap;
}

.bt-join {
  font-family: "Montserrat", Helvetica;
  border-radius: 43px;
  width: 10.5rem;
  height: 3.5rem;
  background: white;
  border-color: orange;
}

.footer-text-wrappe {
  font-family: "Montserrat", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 2.3;
  white-space: nowrap;
  z-index: 10;
}

.background-footer {
  position: relative;
  z-index: 20;
  background: white;
}
</style>
