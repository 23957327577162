<template>
  <div class="text-center">
    <div>
      <h3 class="modal-title w-100 h4 my-5 font-weight-bold">Welcome Back</h3>
      <div>
        <transition name="fade">
          <p class="p mb-3" v-if="loginForm == 'email'">Log in with your email</p>
        </transition>
        <transition name="fade">
          <p class="p mb-3" v-if="loginForm == 'pin'">Log in with your Pin</p>
        </transition>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <div class="overlap-5-position">
          <div class="mb-3">
            <transition name="fade">
              <cake-input
                v-if="loginForm == 'email'"
                v-model="details.email"
                v-bind:type="'email'"
                v-bind:identifier="'email'"
                v-bind:placeholder="'Email'"
                v-bind:isEnabled="isRequestPending"
              />
            </transition>
            <transition name="fade">
              <cake-input
                v-if="loginForm == 'pin'"
                v-model="details.email"
                v-bind:type="'email'"
                v-bind:identifier="'phone'"
                v-bind:placeholder="'Phone Number'"
                v-bind:isEnabled="isRequestPending"
              />
            </transition>
            <transition name="fade">
              <cake-input
                v-if="loginForm == 'email'"
                v-model="details.password"
                v-bind:type="'password'"
                v-bind:identifier="'password'"
                v-bind:placeholder="'Password'"
                v-bind:isEnabled="isRequestPending"
              />
            </transition>
            <transition name="fade">
              <cake-input
                v-if="loginForm == 'pin'"
                v-model="details.password"
                v-bind:type="'password'"
                v-bind:identifier="'pin'"
                v-bind:placeholder="'PIN'"
                v-bind:isEnabled="isRequestPending"
              />
              <small
                v-if="showError && loginForm == 'email'"
                class="form-text text-danger"
              >
                Wrong email or password!
              </small>
              <small v-if="showError && loginForm == 'pin'" class="form-text text-danger">
                Wrong phone or pin!
              </small>
            </transition>
          </div>
          <transition name="fade">
            <a
              v-if="loginForm == 'email'"
              role="button"
              class="mt-3"
              @click="$emit('close')"
              data-target="#forrgotPasswordModal"
              data-toggle="modal"
            >
              Forgot password?
            </a>
          </transition>
          <transition name="fade">
            <a
              v-if="loginForm == 'pin'"
              role="button"
              @click="$emit('close')"
              data-target="#forrgotPasswordModal"
              data-toggle="modal"
              class="mt-3"
            >
              Forgot PIN?
            </a>
          </transition>
        </div>
      </div>
      <div class="my-4">
        <button
          class="btn-log-in"
          type="button"
          data-dismiss="logInModal"
          :disabled="isRequestPending"
          @click="login"
        >
          Login
        </button>
      </div>
      <transition name="fade">
        <a
          v-if="loginForm == 'email'"
          role="button"
          type="button"
          @click="loginForm = 'pin'"
          class="mb-5"
        >
          <div class="login-email">
            <p>Log in with your Pin</p>
          </div>
        </a>
      </transition>
      <transition name="fade">
        <span
          class="mb-5"
          v-if="loginForm == 'pin'"
          role="button"
          type="button"
          @click="loginForm = 'email'"
        >
          <div class="login-email">
            <p>Log in with Email</p>
          </div>
        </span>
      </transition>
    </div>
  </div>
</template>

<script>
import CakeInput from "../components/CakeInput.vue";
export default {
  components: {
    CakeInput,
  },

  data() {
    return {
      details: {
        email: "",
        password: "",
      },
      showError: false,
      isRequestPending: false,
      loginForm: "pin",
    };
  },
  computed: {
    isLogedIn: {
      set(value) {
        this.$store.commit("logedSatus", value);
      },
    },
  },
  methods: {
    login() {
      this.isRequestPending = true;
      window.axios
        .post("/api/customers/users/login", this.details)
        .then(() => {
          this.$router.replace({ name: "CustomerPanel" });
          this.isLogedIn = true;
          this.$emit("close");
        })
        .catch(() => {
          this.showError = true;
        })
        .finally(() => {
          this.isRequestPending = false;
        });
    },
  },
};
</script>

<style>
.fade-enter-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

input .form-control {
  height: 3.2rem;
}

a,
a label {
  cursor: pointer;
}
</style>
