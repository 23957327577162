<template>
  <div class="text-center">
    <div>
      <transition name="fade">
        <h3 v-if="!showSuccess" class="modal-title w-100 h4 my-5 font-weight-bold">
          Register
        </h3>
      </transition>
      <transition name="fade">
        <h3 v-if="showSuccess" class="modal-title w-100 h4 my-5 font-weight-bold">
          Thank you
        </h3>
      </transition>
    </div>
    <div class="text-center d-flex align-items-center justify-content-center">
      <transition name="fade">
        <div v-if="showSuccess" class="overlap-succes">
          <p>
            Thank you for registering. To complete registration, please check your email
            inbox.
          </p>
        </div>
      </transition>
      <transition name="fade">
        <div v-if="!showSuccess">
          <div class="group-56">
            <div class="overlap-6">
              <cake-input
                v-model="details.firstname"
                v-bind:type="'text'"
                v-bind:errors="errors.firstname"
                v-bind:identifier="'first_name'"
                v-bind:placeholder="'First name'"
                v-bind:isEnabled="isRequestPending"
              />
            </div>
          </div>
          <div class="group-57">
            <div class="overlap-6">
              <cake-input
                v-model="details.lastname"
                v-bind:type="'text'"
                v-bind:errors="errors.lastname"
                v-bind:identifier="'last_name'"
                v-bind:placeholder="'Last name'"
                v-bind:isEnabled="isRequestPending"
              />
            </div>
          </div>
          <div class="group-58">
            <div class="overlap-6">
              <cake-input
                v-model="details.email"
                v-bind:type="'email'"
                v-bind:errors="errors.email"
                v-bind:identifier="'email'"
                v-bind:placeholder="'Email'"
                v-bind:isEnabled="isRequestPending"
              />
            </div>
          </div>
          <div class="group-59">
            <div class="overlap-6">
              <cake-input
                v-model="details.phone"
                v-bind:type="'text'"
                v-bind:errors="errors.phone"
                v-bind:identifier="'phone'"
                v-bind:placeholder="'Phone number (optional)'"
                v-bind:isEnabled="isRequestPending"
              />
            </div>
          </div>
          <div class="group-60">
            <div class="overlap-6">
              <cake-input
                v-model="details.password"
                v-bind:type="'password'"
                v-bind:errors="errors.password"
                v-bind:identifier="'password'"
                v-bind:placeholder="'Password'"
                v-bind:isEnabled="isRequestPending"
              />
            </div>
          </div>
          <div class="group-62">
            <div class="overlap-8">
              <cake-input
                v-model="details.confirm_password"
                v-bind:type="'password'"
                v-bind:errors="errors.confirm_password"
                v-bind:identifier="'confirm_password'"
                v-bind:placeholder="'Repeat password'"
                v-bind:isEnabled="isRequestPending"
              />
            </div>
          </div>
          <div class="group-63">
            <div class="overlap-7">
              <cake-input
                v-model="details.refcode"
                v-bind:type="'text'"
                v-bind:errors="errors.refcode"
                v-bind:identifier="'refcode'"
                v-bind:placeholder="'Referal code'"
                v-bind:isEnabled="isRequestPending"
              />
            </div>
          </div>

          <input
            class="group-61"
            type="checkbox"
            id="checkbox_id"
            name="terms"
            :value="details.acceptTerms"
          />
          <span class="p mt-4"
            >By pressing Register I accept T-Elephant <br /><b class="bolded"
              ><a href=""> terms of service</a></b
            >
            and
            <b class="bolded"><a href=""> privacy policy</a></b></span
          >
        </div>
      </transition>
    </div>
    <transition name="fade">
      <div class="my-3" v-if="!showSuccess">
        <div class="overlap-group">
          <button class="btn-register" :disabled="isRequestPending" @click="register">
            <span v-if="!isRequestPending">Register</span>
            <span v-if="isRequestPending">Sending..</span>
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import CakeInput from "../components/CakeInput.vue";

export default {
  components: {
    CakeInput,
  },

  data: function () {
    return {
      acceptTermsMessage: false,
      details: {
        email: "",
        firstname: "",
        lastname: "",
        password: "",
        confirm_password: "",
        phone: "",
        refcode: "",
      },
      isRequestPending: false,
      errors: {
        confirm_password: {},
      },
      showSuccess: false,
      acceptTerms: false,
    };
  },

  methods: {
    register: function () {
      this.isRequestPending = true;
      window.axios
        .post("/api/customers/users/register", this.details)
        .then(() => {
          this.isRequestPending = false;
          this.showSuccess = true;
        })
        .catch((err) => {
          this.isRequestPending = false;
          this.errors = err.response.data;
        });
    },
  },
};
</script>

<style>
.overlap-succes {
  position: relative;
  width: 520px;
  height: 55px;
  top: 120px;
  font-size: 20px;
  left: -46px;
  background-color: #ffffff;
}
.rectangle-succes {
  position: absolute;
  width: 737px;
  height: 285px !important;
  top: 0px;
  left: -136px;
  background-color: #ffffff;
  border-radius: 28px;
  box-shadow: 0px 3px 3px #7d7d7d29;
}

.fade-enter-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.text-wrapper-succes {
  top: 32px;
  left: 172px;
  font-weight: 600;
  color: #000000;
  font-size: 24px;
  line-height: normal;
  position: relative;
  font-family: "Montserrat", Helvetica;
  letter-spacing: 0;
  white-space: nowrap;
}

.checkbox-error {
  outline: 2px solid #dc3545;
}
</style>
