<template>
  <div class="">
    <div class="collors-wrapper">
      <div class="img-topbar-red">
        <img class="img-fluid" src="/img/telephant/rectangle-467-3.png" />
      </div>
      <div class="img-topbar-green">
        <img class="img-fluid" src="/img/telephant/rectangle-461-2.png" />
      </div>
    </div>
    <nav class="navbar navbar-light navbar-expand-lg topbar mb-4 static-top index-top">
      <a class="navbar-brand" href="#"
        ><img class="" src="/img/telephant/djumbo-02-05-1.png"
      /></a>
      <button
        class="navbar-toggler mt-auto ml-1 mr-auto"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto ml-auto form-inline">
          <li class="nav-item dropdown">
            <a
              class="nav-link mt-2 dropdown text-wrapp"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Services
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdown">
              <router-link class="dropdown-item" type="button" to="international"
                >International top-up
              </router-link>
              <router-link class="dropdown-item" type="button" to="calling-rates"
                >International calling rates
              </router-link>
              <button
                class="dropdown-item"
                data-target="#refillModal"
                data-toggle="modal"
                id="refillModalLink"
                type="button"
                href="#topUPModal"
              >
                Top-up
              </button>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown mt-2 text-wrapp"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Help
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdown2">
              <router-link class="dropdown-item" type="button" to="support"
                >Contact support
              </router-link>
              <button class="dropdown-item" type="button">Cookie policy</button>
              <button class="dropdown-item" type="button">Legal Information</button>
              <button class="dropdown-item" type="button">Terms of Service</button>
              <button class="dropdown-item" type="button">Privacy Policy</button>
              <router-link class="dropdown-item" type="button" to="invite-bonus"
                >Invite Bonus
              </router-link>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown mt-2 text-wrapp"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              About us
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdown3">
              <router-link class="dropdown-item" type="button" to="t-elephant"
                >T-elephant
              </router-link>
              <router-link class="dropdown-item" type="button" to="how-it-work"
                >How it works
              </router-link>
            </div>
          </li>
        </ul>
        <ul class="navbar-nav mr-auto ml-auto form-inline">
          <li class="nav-item dropdown">
            <transition name="fade">
              <a
                v-if="!isLogedIn"
                class="nav-link mt-2"
                data-target="#joinModal"
                data-toggle="modal"
                id="joinModalLink"
                href="#joinModal"
              >
                Join
              </a>
            </transition>
            <transition name="fade">
              <a
                v-if="isLogedIn"
                class="nav-link mt-2 dropdown text-wrapp"
                data-toggle="modal"
                data-target="#logOutModal"
              >
                Log out
              </a>
            </transition>
          </li>
          <div class="nav-item">
            <transition name="fade">
              <button
                v-if="!isLogedIn"
                class="nav-link mt-2 bt-wrapper-top-bar btn-link"
                data-target="#logInModal"
                @click="$emit('open')"
                data-toggle="modal"
                id="logInModalLink"
                href="#logInModal"
              >
                Log in
              </button>
            </transition>
            <transition name="fade">
              <button v-if="isLogedIn" class="nav-link mt-2 bt-wrapper-top-bar btn-link">
                <router-link to="customer">Return to panel </router-link>
              </button>
            </transition>
          </div>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown mt-2 bolded"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              EN
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
              <button class="dropdown-item" type="button">PL</button>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <div
      class="modal fade"
      id="logOutModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body text-center">
            <div>
              <p class="h3">Are you sure you want to log out?</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn-log-out mr-3" data-dismiss="modal">
              <span class="button-text-modal"> No</span>
            </button>
            <button
              type="button"
              @click="logout()"
              data-dismiss="modal"
              class="btn-log-out mr-3"
            >
              <div class="button-text-modal">Yes</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    isLogedIn: {
      get() {
        return this.$store.getters.logedSatus;
      },
      set(value) {
        this.$store.commit("logedSatus", value);
      },
    },
  },
  mounted() {
    window.axios.get("/api/customers/users/user").then((response) => {
      if (response.data) {
        this.isLogedIn = true;
      } else {
        this.isLogedIn = false;
      }
    });
  },
  methods: {
    logout() {
      this.isRequestPending = true;
      window.axios
        .post("/api/customers/users/logout")
        .then(() => {
          this.isLogedIn = false;
          this.$router.replace({ name: "Welcome" });
        })
        .catch(() => {
          this.showError = true;
        })
        .finally(() => {
          this.isRequestPending = false;
        });
    },
  },
};
</script>

<style>
.index {
  z-index: 30;
}

.group-navbar {
  position: absolute;
  width: 100%;
  height: 55px;
  top: 693px;
  /* top: 0; */
  z-index: 12;
}

.text-wrapp {
  font-weight: 400;
  font-family: "Montserrat", Helvetica;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  z-index: 12;
}

.navbar .navbar-brand {
  margin-left: 10% !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #000000;
}

.btn-text-wrapper {
  position: absolute;
  width: 50px;
  top: 16px;
  left: 24px;
  font-family: "Montserrat", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
  white-space: nowrap;
}

.btn-log-out {
  position: relative;
  width: 100px;
  height: 55px;
  font-family: "Montserrat", Helvetica;
  border-radius: 43px;
  color: white;
  background: linear-gradient(180deg, rgb(253, 194, 2) 0%, rgb(253, 133, 33) 100%);
  border: white;
}

.h1 {
  color: #1f1f1f;
  font-family: "Montserrat", Helvetica;
}
.overlap-5 {
  border: white;
  font-family: "Montserrat", Helvetica;
}
.button-text-modal {
  font-family: "Montserrat", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 2.3;
}

.mr-10 {
  margin-right: 13rem !important;
}

form button {
  max-height: 3.5rem !important;
}

.collors-wrapper {
  position: relative;
  display: block;
}

.img-topbar-red {
  z-index: 15;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-20%, -60%);
}

.img-topbar-green {
  z-index: 15;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(40%, -50%);
}

.index-top {
  z-index: 80;
}
</style>
