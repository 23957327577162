<template>
  <div>
    <div>
      <div class="text-center">
        <div>
          <h3 class="modal-title w-100 h4 my-5 font-weight-bold">Top-up</h3>
          <div v-if="!topUpReady">
            <div class="d-flex align-items-center justify-content-center">
              <div class="mb-3">
                <cake-input
                  v-model="user.firstname"
                  v-bind:type="'text'"
                  v-bind:identifier="'fristName'"
                  v-bind:placeholder="'First Name'"
                  v-bind:isEnabled="isRequestPending"
                />

                <cake-input
                  v-model="user.lastname"
                  v-bind:type="'text'"
                  v-bind:identifier="'lastName'"
                  v-bind:placeholder="'Last Name'"
                  v-bind:isEnabled="isRequestPending"
                />

                <cake-input
                  v-model="user.email"
                  v-bind:type="'email'"
                  v-bind:identifier="'email'"
                  v-bind:placeholder="'Email'"
                  v-bind:isEnabled="isRequestPending"
                />

                <cake-input
                  v-model="user.phone"
                  v-bind:type="'number'"
                  v-bind:identifier="'number'"
                  v-bind:placeholder="'Phone number'"
                  v-bind:isEnabled="isRequestPending"
                />
              </div>
            </div>
            <div class="row mx-auto">
              <div
                v-for="(refill, index) in availableAmounts"
                class="col-md-2 mb-2 mx-md-1"
                :key="index"
              >
                <a
                  href="#"
                  :class="{ active: refill.amount == amount }"
                  type="button"
                  class="top-up-button ml-4"
                  :for="'amount' + index"
                  @click="changeAmount(refill.amount, index)"
                >
                  <span>{{ refill.amount }} €</span>
                </a>
                <div v-if="refill.promo && isPromoAvailable">
                  + {{ refill.promo }} € for calls!
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <button class="top-up-img-button" @click="changeOperator('paypal')">
                <img
                  class="img-fluid"
                  style="max-height: 50px"
                  src="/img/telephant/paypal-seeklogo.svg"
                />
              </button>
              <button class="top-up-img-button" @click="changeOperator('p24')">
                <div class="text-wrapper-23">Przelewy24</div>
                <i class="bi bi-bank"></i>
                <img class="img-fluid" src="/img/telephant/bank.svg" />
              </button>
              <button class="top-up-img-button" @click="changeOperator('card')">
                <div class="text-wrapper-24">Card</div>
                <img class="img-fluid" src="/img/telephant/credit-card.svg" />
              </button>
            </div>
            <div class="mt-2">
              <input
                class="mr-2"
                type="checkbox"
                id="checkbox_id"
                name="terms"
                v-model="acceptTos"
              />
              <p>
                <span
                  >I accept T-Elephant
                  <b class="bolded h5"><a href=""> terms of service </a></b> and
                  <b class="bolded h5"><a href=""> privacy policy</a></b></span
                >
              </p>
            </div>
            <div class="my-4">
              <button
                @click="refill"
                class="btn-log-in"
                type="button"
                data-dismiss="logInModal"
                :disabled="isRequestPending || !acceptTos"
              >
                Top-up
              </button>
            </div>
          </div>
          <div v-else>
            <p class="mb-2">Refill value: {{ amount }}.00</p>
            <p class="mb-2">First name: {{ user.firstname }}</p>
            <p class="mb-2">Last name: {{ user.lastname }}</p>
            <p class="mb-4">email: {{ user.email }}</p>

            <button class="btn-log-in mb-4" type="button" @click="sendP24Refill">
              Pay with Przelewy24
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CakeInput from "../components/CakeInput.vue";

export default {
  data() {
    return {
      amount: 10,
      isRequestPending: false,
      isDataReady: false,
      isPromoAvailable: false,
      availableAmounts: [],
      user: {
        firstname: null,
        lastname: null,
        phone: null,
        email: null,
      },
      selectedOperator: "p24",
      acceptTos: false,
      paymentId: null,
      tpayId: null,
      notifyUrl: null,
      md5: null,
      topUpReady: false,
      p24link: "",
    };
  },

  components: {
    CakeInput,
  },

  mounted() {
    window.axios.get("/api/customers/refills/list").then((response) => {
      this.isPromoAvailable = response.data.promo;
      this.availableAmounts = response.data.refills;
      this.isDataReady = true;
    });
    window.axios.get("/api/customers/users/user").then((response) => {
      this.user = response.data;
    });
  },

  methods: {
    refill() {
      if (this.isRequestPending) {
        return;
      }

      this.isRequestPending = true;

      if (this.selectedOperator == "p24") {
        window.axios
          .post("/api/customers/refills/refill", { amount: this.amount })
          .then((response) => {
            //this.$router.replace({ name: "CustomerPanel" });
            this.p24link = response.data.token;
          });
      }
    },

    changeAmount(value, index) {
      console.log(index);
      this.amount = value;
    },

    changeOperator(val) {
      this.selectedOperator = val;
    },

    sendP24Refill() {
      window.location.replace("https://secure.przelewy24.pl/trnRequest/" + this.p24link);
    },
  },
};
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.top-up-button {
  width: 118px;
  height: 55px;
  background-color: #ffffff;
  border-radius: 28px;
  border: 1px solid;
  border-color: grey;
  box-shadow: 0px 3px 3px #7d7d7d29;
  transform: none;
  display: inline-block;
  vertical-align: middle;
  line-height: 55px;
  text-align: center;
}

.active {
  border-color: #4dff00;
}

.top-up-img-button {
  border-color: white;
  position: relative;
  width: 123px;
  height: 55px;
  background-color: #ffffff;
  border-radius: 28px;
  box-shadow: 0px 3px 3px #7d7d7d29;
  margin-bottom: 2rem;
}
</style>
