<template>
  <div class="background">
    <div class="modal fade" id="joinModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg">
        <div class="modal-content rounded">
          <register> </register>
        </div>
      </div>
      <button
        type="button"
        id="dissmisModal"
        class="close-modal"
        data-dismiss="modal"
      ></button>
    </div>
    <top-bar @open="openLogin = true"> </top-bar>
    <main class="d-flex flex-column mb-3">
      <div class="p-2">
        <div v-if="sidebarCondition" class="row  mx-lg-5 mb-lg-5 mb-5">
          <div class="col-md-3 text-center">
            <div class="center">
              <sidebar> </sidebar>
            </div>
          </div>
          <div class="col-md-8">
            <transition name="fade">
              <router-view />
            </transition>
          </div>
        </div>
        <div v-if="!sidebarCondition">
          <router-view />
        </div>
      </div>
    </main>
    <div class="p-2 footer navbar-fixed-bottom mt-4">
      <footerComponent> </footerComponent>
    </div>
    <div>
      <div
        v-if="openLogin"
        class="modal fade"
        id="logInModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="logInModalCenterTitle"
      >
        <div class="modal-dialog">
          <div class="modal-content rounded">
            <login @close="closeLogin()"> </login>
          </div>
        </div>
        <button
          type="button"
          id="dissmisModalLogin"
          class="close-modal"
          data-dismiss="modal"
        ></button>
      </div>
    </div>
    <div
      class="modal fade"
      id="refillModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="refillModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content rounded">
          <refill> </refill>
        </div>
      </div>
    </div>
    <div
      id="forrgotPasswordModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <resetPassword />
    </div>
  </div>
</template>

<script>
import Refill from "./views/Refill.vue";
import footerComponent from "./components/Footer.vue";
import TopBar from "./components/TopBar";
import Login from "./views/Login.vue";
import Register from "./views/Register.vue";
import Sidebar from "./components/Sidebar.vue";
import ResetPassword from "./views/ResetPasswordRequest";
// import Welcome from './views/Welcome.vue';
export default {
  components: {
    footerComponent,
    TopBar,
    // // Welcome,
    Register,
    Login,
    Sidebar,
    Refill,
    ResetPassword,
  },
  data() {
    return {
      secondStep: false,
      layout: false,
      openLogin: false,
    };
  },
  computed: {
    currentRoute() {
      return this.$route.name;
    },
    sidebarCondition() {
      if (
        [
          "CustomerPanel",
          "Refill",
          "TransactionList",
          "Details",
          "TransfersList",
        ].includes(this.currentRoute)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  watch: {
    currentRoute() {
      if (["Verify"].includes(this.currentRoute)) {
        this.layout = false;
      } else {
        this.layout = true;
      }
    },
  },
  methods: {
    closeLogin() {
      document.getElementById("dissmisModalLogin").click();
    },
  },
};
</script>

<style>
.card {
  top: 10px;
}

.join .rectangle-10 {
  height: 670px;
}

.join .rectangle-register {
  height: 0px;
  top: 0px;
}

.form-control {
  border-radius: 50em !important;
  font-family: "Montserrat", Helvetica;
  font-weight: 400;
  min-width: 300px;
  color: #9e9e9e;
}

.close-modal {
  visibility: hidden;
}

.background {
  background-color: #f4f0ea;
  width: 100%;
  right: 0;
  font-family: "Montserrat", Helvetica;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.navbar-fixed-bottom {
  background: white;
}

.footer-margin {
  margin-top: 300px;
}

.rounded {
  border-radius: 10px !important;
}

main {
  min-height: 80vh;
  height: auto !important;
  height: 100%;
  margin: 0 auto -60px;
}
</style>
