<template>
  <div>
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header border-0">
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
            ×
          </button>
        </div>
        <div class="modal-body-lg">
          <div class="col-md-12">
            <div class="panel panel-default">
              <div class="panel-body">
                <div v-if="!requestCompleted" class="text-center">
                  <p class="my-3">
                    If you have forgotten your password you can reset it here.
                  </p>
                  <div class="panel-body">
                    <fieldset>
                      <div class="form-group mb-3">
                        <cake-input
                          v-model="details.email"
                          v-bind:type="'email'"
                          v-bind:identifier="'email'"
                          v-bind:placeholder="'Your Email'"
                          v-bind:isEnabled="isRequestPending"
                        />
                      </div>
                      <button
                        class="bt-wrapper mb-3"
                        :disabled="isRequestPending"
                        @click="resetPassword"
                        value="Send My Password"
                        type="submit"
                      >
                        <span v-if="!isRequestPending">Send My Password</span>
                        <span v-if="isRequestPending">Sending..</span>
                      </button>
                    </fieldset>
                  </div>
                </div>
                <div class="my-5" v-else>
                  <p>
                    If provided email address is registered in our service we will send a
                    message with password reset link.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CakeInput from "../components/CakeInput.vue";

export default {
  components: {
    CakeInput,
  },

  data() {
    return {
      details: {
        email: "",
      },
      isRequestPending: false,
      requestCompleted: false,
    };
  },

  methods: {
    resetPassword() {
      this.isRequestPending = true;
      window.axios
        .post("/api/customers/users/password-reset-request", this.details)
        .finally(() => {
          this.requestCompleted = true;
        });
    },
  },
};
</script>
