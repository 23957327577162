<template>
  <div class="form-group">
    <label v-if="label" :for="identifier">{{ label }}</label>
    <input
      v-if="type != 'number'"
      :type="type"
      class="form-control"
      :class="{ 'is-invalid': errors }"
      :id="identifier"
      :placeholder="placeholder"
      v-bind:value="value"
      :enabled="isEnabled"
      v-on:input="$emit('input', $event.target.value)"
    />
    <input
      v-else
      :type="type"
      class="form-control"
      :class="{ 'is-invalid': errors }"
      :placeholder="placeholder"
      :id="identifier"
      v-bind:value="value"
      :enabled="isEnabled"
      v-on:input="$emit('input', $event.target.value)"
      min="0"
    />
    <div class="invalid-feedback" v-if="errors">
      <small class="form-text text-danger" v-for="error in errors" :key="error">{{
        error
      }}</small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: { required: false, type: String },
    type: { required: false, type: String, default: "text" },
    identifier: { required: false, type: String, default: "" },
    isEnabled: { required: false, type: Boolean, default: true },
    placeholder: { required: false, type: String, default: "" },
    errors: {
      required: false,
      type: Object,
      default() {
        return {};
      },
    },
    value: { required: true, type: String },
  },
};
</script>

<style>
.border {
  border-color: blue;
}
</style>
