window.axios = require('axios');

import Vue from 'vue'
Vue.prototype.$APIPrefix = '/customers/';

import App from './App.vue'
import router from './router';
import store from './store';

// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.config.productionTip = false
Vue.component('app-component', App);



new Vue({
  el: '#app',
  router,
  store
});
